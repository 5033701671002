.kn-navigation{
    z-index:1000;
    position: fixed;
    top:50%;
    transform: translateY(-50%);
    right:0px;
    padding: 10px;
    background-color: #273243;
    transition: all 0.3s ease;
    overflow: hidden;
    max-width: 60px;
}

.kn-navigation:hover{
    max-width: 200px;

}


.kn-navigation > div{
    display: flex;
    flex-direction: column;
}
.kn-navigation > div > button{
    border:none;
    outline: none;
    background: none;
    cursor: pointer;
    margin:4px;
}

.kn-navigation-button{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 10px !important; 
}

.kn-navigation-label{
    font-family: 'Cinzel', serif;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: #f2c865;
}

.kn-navigation-diamond{
    display: flex;

    background: #f2c865;
    transform: rotateZ(45deg);
    box-shadow: 0px 0px 24px #000000;
}

.kn-navigation-button .kn-navigation-diamond{
    width:16px;
    height:16px;
    transition: all 0.3s ease;
}

.kn-navigation-button:hover .kn-navigation-diamond{
    width:20px;
    height:20px;
}

.kn-navigation-button .kn-navigation-label{
    opacity: 0;
    max-width: 0px;
    margin-right: 0px;
    max-height: 24px;
    overflow: hidden;
    pointer-events: none;
    transition: all 0.3s ease;
}

.kn-navigation-button:hover .kn-navigation-label{
    display: inline;
    max-width: 200px;
    opacity: 1;
    pointer-events: all;
    margin-right: 24px;

}
