.kn-products-section-right{
    background: url( "../images/backround-products.jpg");
    padding: 32px;
    box-shadow: 0px 0px 24px #0000008c;
    position: relative;
    z-index: 1;
}

.kn-products-title-right{
    font-family: 'Cinzel', serif;
    font-size: 36px;
    margin-bottom: 16px;
    max-width: 720px;
    color: #273243;
    margin-left: auto;
    text-align: end;
}

.kn-products-text-right{
    font-family: 'Lora', serif;
    font-size: 18px;
    line-height: 32px;
    margin-top: 32px;
    margin-bottom: 48px;
    max-width: 720px;
    color: #273243;
    margin-left: auto;
    text-align: end;
}

.kn-products-button-container-right{
    margin:16px 0px 16px 0px;
    max-width: 720px;
    margin-left: auto;

}

.kn-products-read-more-right{
    border: 0px;
    outline: 0px;
    background: transparent;
}

.kn-products-text-container-right{
    width: calc(50% - 12px);
    margin-right: 50%;
    margin-left: auto;
    margin-top: 0px;
    padding-top: 0px;
    margin-bottom: 32px;
}

.kn-products-read-more-right{
    font-family: 'Cinzel', serif;
    font-size: 16px;
    text-decoration:none;
    color:#273243;
    cursor:pointer;
    display: block;
    position: relative;
    margin-left: auto;
    margin-right: 50px;
    left:0px;
    transition: all 0.3s ease;
    font-weight: bold;
    border: none;
    outline: none;
    background: transparent;
}

.kn-products-read-more-right::before{
    content:"";
    display: flex;
    position: absolute;
    width: 40px;
    background: #273243;
    height: 2px;
    top:50%;
    left:-50px;
    transform: translateY(-50%);
    transition: all 0.3s ease;
}

.kn-products-read-more-right:hover{
    left:20px;
    color:#2732438c;
}

.kn-products-read-more-right:hover::before{
    width: 60px;
    left:-70px;
}