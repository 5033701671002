.kn-footer{
    width: calc( 100% - 100px);
    padding: 25px 50px;
    position: relative;
    background: #131a24;
}

.kn-credits p{
    width:100%;
    font-family: 'Cinzel', serif;
    font-size: 16px;
    text-decoration: none;
    text-align: center;
    color: #f2c865;
}
