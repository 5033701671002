.kn-articles-slider{
    transition:all 0.4s ease-in-out;
    display:flex;
    justify-content:flex-start;
    align-items: flex-start;
    position:relative;
}
.kn-outer-article-container{
    overflow: hidden;
    width: 50%;
    margin-left:auto;
    position: relative;
    
}

.kn-article-slider-controls{
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    pointer-events: none;
}
.kn-article-button-prev{
    position: absolute;
    top:50%;
    left:0;
    pointer-events: all;
    transform: translateY(-50%);
    width: 100px;
    outline: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: transparent;
    text-shadow: 0px 0px 3px #000;
}

.kn-article-button-prev > i{
    font-size: 64px;
    color: #fff;
}

.kn-article-button-next{
    position: absolute;
    top:50%;
    width: 100px;
    right:0;
    pointer-events: all;
    outline: none;
    border: none;
    display: flex;
    transform: translateY(-50%);
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: transparent;
    text-shadow: 0px 0px 3px #000;
}

.kn-article-button-next > i{
    font-size: 64px;
    color: #fff;
}

.kn-article-slider-bottle-left{
    width: 50%;
    display: flex;
    margin:0px;
    justify-content: center;
    align-items: flex-start;
}
.kn-article-slider-bottle-left img{
    max-width: 400px;
    object-fit: contain;
    opacity: 0.2;
}

.kn-article-slider-container{
    display: flex;
    flex-wrap: wrap;
}
