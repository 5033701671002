.kn-contact-slider-container{
    padding: 100px;
    background-color: #273243;
}

.kn-contact-inner-container{
    max-width: 1440px;
    position: relative;
    margin:auto;
    display: flex;
    flex-wrap: wrap;
}

.kn-contact-container-half{
    width: 50%;
    position: relative;
}

.kn-contact-form-title{
    font-family: 'Cinzel', serif;
    font-size: 36px;
    margin: 0px auto;
    margin-bottom: 0px;
    color: #f2c865;
    width:450px;
}

.kn-contact-diamond{
    display: flex;
    width: 20px;
    height: 20px;
    background: #f2c865;
    margin: 32px 225px 16px;
    transform: rotateZ(45deg);
}

.kn-contact-form{
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: flex-start;
}
/**/
.kn-contact-form-input{
    width:450px;
    font-family: 'Lora', serif;
    font-size: 16px;
    text-decoration: none;
    color: #c1b18d;
    cursor: pointer;
    display: block;
    position: relative;
    transition: all 0.3s ease;
    border: none;
    border-bottom: 2px solid #f2c865;
    outline: none;
    padding: 3px 8px;
    margin-bottom: 16px;
    background: transparent;
}

.kn-contact-form-textarea{
    width:450px;
    font-family: 'Lora', serif;
    font-size: 16px;
    text-decoration: none;
    color: #c1b18d;
    cursor: pointer;
    display: block;
    position: relative;
    transition: all 0.3s ease;
    border: none;
    border-bottom: 2px solid #f2c865;
    outline: none;
    padding: 3px 8px;
    margin-bottom: 16px;
    background: transparent;
    height:100px;
    resize: none;
}

.kn-contact-form-submit{
    margin-top: 16px;
    font-family: 'Cinzel', serif;
    font-size: 16px;
    text-decoration: none;
    color: #f2c865;
    cursor: pointer;
    display: block;
    position: relative;
    margin-left: 50px;
    left: 0px;
    transition: all 0.3s ease;
    font-weight: bold;
    border: none;
    outline: none;
    background: transparent;
}
.kn-contact-form-submit::before{
    content:"";
    display: flex;
    position: absolute;
    width: 40px;
    background: #c1b18d;
    height: 2px;
    top:50%;
    left:-50px;
    transform: translateY(-50%);
    transition: all 0.3s ease;
}

.kn-contact-form-submit:hover{
    left:20px;
    color:#f2c8658c;
}

.kn-contact-form-submit:hover::before{
    width: 60px;
    left:-70px;
}

.kn-ladning-diamond{
    display: flex;
    width:20px;
    height: 20px;
    background: #f2c865;
    margin: 32px auto 16px;
    transform: rotateZ(45deg);
}