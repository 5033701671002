.kn-about-us-section{
    background: url( "../images/backround-products.jpg");
    padding: 64px 32px;
    box-shadow: 0px 0px 24px #0000008c;
    position: relative;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
}

.kn-about-us-section-half-left{
    max-width: 720px;
    margin-left:auto;
    width: 50%;
}

.kn-about-us-section-half-right{
    max-width: 720px;
    margin-right:auto;
    width: 50%;
}

.kn-about-us-section-container h3{
    font-family: 'Cinzel', serif;
    font-size: 36px;
    margin-bottom: 16px;
    text-align: center;
    color: #273243;
}

.kn-about-us-section-container p{
    font-family: 'Lora', serif;
    font-size: 18px;
    line-height: 32px;
    margin-top: 32px;
    margin-bottom: 48px;
    color: #273243;
    text-align: justify;
}

.kn-about-us-section-image{
    width:400px;
    margin: auto;
    display: flex;
}

.kn-about-us-separator{
    margin: 36px auto;
    display: block;
    width: 200px;
    height: 2px;
    background: #273243;
}