.kn-landing-section{
    display: flex;
    justify-content: center;
    align-items:center;
    position:relative;
    background:url(../images/landing-bg.jpg);
    background-repeat:no-repeat;
    background-size: cover;
    height:1000px;
    background-attachment: fixed;
    background-position: bottom;
}

.kn-landing-content-block{
    width: 450px;
    height:800px;
    display:flex;
    flex-direction:column;
    position:relative;
    margin:auto 20px;
    justify-content: center;
    align-items:flex-start;
    box-shadow: 0px 1px 10px #00000066;

}

.kn-landing-content-block-3 img{
    height: 100%;
    object-fit: cover;
    width:100%;
}
.kn-landing-content-block-3{
    
    top:30px;
}

.kn-landing-content-block-2 video{
    height: 100%;
    object-fit: cover;
    width: 100%;
}

.kn-landing-content-block-1{
    background-color:#273243;
    top:-30px;
    padding:24px;
    width: 402px;
    height: 752px;
}
.kn-landing-content-block-1{
    font-family: 'Lora', serif;
}
.kn-landing-content-block-1 h2{
    font-family: 'Cinzel', serif;
    font-size: 36px;
    margin: 0px auto;
    margin-bottom: 0px;
    color:#f2c865;
}
.kn-landing-content-block-1 p{
    font-family: 'Lora', serif;
    font-size:18px;
    font-weight: 200;
    margin-bottom:32px;
    margin-top:32px;
    line-height: 32px;
    text-align: justify;
    color:#c1b18d;
}


.kn-landing-content-block-1 button{
    font-family: 'Cinzel', serif;
    font-size: 16px;
    text-decoration:none;
    color:#f2c865;
    cursor:pointer;
    display: block;
    position: relative;
    margin-left: 50px;
    left:0px;
    transition: all 0.3s ease;
    font-weight: bold;
    border: none;
    outline: none;
    background: transparent;
}

.kn-landing-content-block-1 button::before{
    content:"";
    display: flex;
    position: absolute;
    width: 40px;
    background: #c1b18d;
    height: 2px;
    top:50%;
    left:-50px;
    transform: translateY(-50%);
    transition: all 0.3s ease;
}

.kn-landing-content-block-1 button:hover{
    left:20px;
    color:#f2c8658c;
}

.kn-landing-content-block-1 button:hover::before{
    width: 60px;
    left:-70px;
}

.kn-ladning-diamond{
    display: flex;
    width:20px;
    height: 20px;
    background: #f2c865;
    margin: 32px auto 16px;
    transform: rotateZ(45deg);
}