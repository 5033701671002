.kn-wines-article{
    width:400px;
    margin:12px;
    height:600px;
    

}

.kn-wines-article img{
    width:100%;
}

.kn-wines-article h3{
    font-family: 'Cinzel', serif;
    font-size: 24px;
    margin-bottom: 16px;
    color: #273243;
}

.kn-wines-article p{
    font-family: 'Lora', serif;
    font-size: 16px;
    margin-bottom: 16px;
    color: #273243;
}

.kn-wines-article a{
    font-family: 'Cinzel', serif;
    font-size: 16px;
    text-decoration:none;
    color:#273243;
    cursor:pointer;
    display: block;
    position: relative;
    margin-left: 50px;
    left:0px;
    transition: all 0.3s ease;
    font-weight: bold;
}

.kn-wines-article a::before{
    content:"";
    display: flex;
    position: absolute;
    width: 40px;
    background: #273243;
    height: 2px;
    top:50%;
    left:-50px;
    transform: translateY(-50%);
    transition: all 0.3s ease;
}

.kn-wines-article a:hover{
    left:20px;
    color:#2732438c;
}

.kn-wines-article a:hover::before{
    width: 60px;
    left:-70px;
}