.kn-background-divider-1{
    padding: 200px;
    background: url("../images/products-bg.jpg");
    background-position: center;
    background-size: 100vw auto;
    background-attachment: fixed;
    background-repeat: no-repeat;
}

.kn-background-divider-2{
    padding: 200px;
    background: url("../images/separator-bg2.jpg");
    background-position: center top;
    background-size: 100vw auto;
    background-attachment: fixed;
    background-repeat: no-repeat;  
}